<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 内容列表</van-col
        >
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveContentList"
      >
        <template #default>
          <div
            class="content"
            v-for="content in contentList"
            :key="content.code"
            @click="detail(content.type, content.code)"
          >
            <van-swipe class="banner" :autoplay="3000" indicator-color="white">
              <van-swipe-item v-for="img in content.imagesPaths" :key="img">
                <img class="img" :src="img" />
              </van-swipe-item>
            </van-swipe>
            <div class="title text-short">{{ content.title }}</div>
            <div class="subtitle">{{ content.subTitle }}</div>
            <van-row class="count">
              <van-col :span="8" class="item"
                >收藏：{{ content.collectTotal }}次</van-col
              >
              <van-col :span="8" class="item"
                >点赞：{{ content.praiseTotal }}次</van-col
              >
              <van-col :span="8" class="item">
                阅读：{{ content.readTotal }}次</van-col
              >
            </van-row>
          </div>
        </template>
      </van-list>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="CNT" module-label="CNT_HOME_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
