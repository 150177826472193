import { Search, Icon, Swipe, SwipeItem } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            page: { current: 0, size: 10, last: 0 },
            contentList: []
        }
    },
    mounted () {
        this.retrieveContentList()
    },
    methods: {
        search () {
            this.page.current = 0
            this.finished = false
            this.contentList = []
            this.retrieveContentList()
        },
        async retrieveContentList () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { keyword: this.keyword, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/content/index/retrieveAPCIndexList', this.$qs.stringify(pd))
                if (res.status === '200') {
                    console.log(res.data)
                    this.contentList = this.contentList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        detail (type, code) {
            console.log(type)
            if (type === 'ATC') {
                var key = window.const.global.CONTENT_ARTICLE_INDEX_BACK
                window.sessionStorage.setItem(key, window.location.href)
                this.$router.push({ path: '/mde/content/article', query: { indexCode: code } })
            }
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.CONTENT_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
